<template>
  <div id="container" v-if="fetch_data">

    <section id="alert">
      <div class="wrap">
        <h2 class="sub_ttl"><span>通知一覧</span></h2>
        <h3 class="min_ttl">通知の絞り込み</h3>
        <p>
          絞り込みたい検索項目をチェックしてください。（複数可）
        </p>
        <div class="alert_search">
          <div class="checkbox c_red">
            <input id="check01" type="checkbox" v-model="filter_comment" v-on:change="pageChanged(1)">
            <label for="check01">コメント</label>
          </div>
          <div class="checkbox c_red">
            <input id="check02" type="checkbox" v-model="filter_post" v-on:change="pageChanged(1)">
            <label for="check02">新規の投稿</label>
          </div>
          <div class="checkbox c_red">
            <input id="check03" type="checkbox" v-model="filter_like" v-on:change="pageChanged(1)">
            <label for="check03">いいね！</label>
          </div>
          <div class="checkbox c_red">
            <input id="check05" type="checkbox" v-model="filter_follow" v-on:change="pageChanged(1)">
            <label for="check05">フォローされた時</label>
          </div>
        </div>
        <a href="javascript:void(0)" v-if="false" v-on:click="checkOff()" class="blue bold">すべてのチェックをはずす</a>

        <div class="contents">
          <ul class="alert_list">
            <li class="flex" v-for="alert in fetch_data.alerts" :key="alert.id">
              <router-link :to="{ name: 'SnsTimeline', params: {user_id: alert.from_user_id}}" class="icon">
                <img v-bind:src="alert.image_url" alt="">
              </router-link>
              <router-link v-bind:to="'/' + alert.url" class="info">
                <h3>{{ alert.title }}</h3>
                <p>
                  {{ alert.description }}</p>
                <p class="date">{{ moment(alert.created_at, 'YYYY/M/D') }}</p>
              </router-link>
            </li>
          </ul>
          <Pagination :pagination="fetch_data.pagination" v-on:changed="pageChanged"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import Pagination from '@/components/Pagination.vue'

export default {
  mixins: [Libraries],
  components: {
    Pagination
  },
  data() {
    return this.initialize()
  },
  watch: {
    $route(/*to, from*/) {
      this.reset()
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    initialize() {
      const filter = this.$route.query.filter == null ? '' : this.$route.query.filter
      const filters = filter.length === 0 ? [] : filter.split(',')
      return {
        fetch_data: null,
        page: this.$route.query.page == null ? 1 : Number(this.$route.query.page),
        filter: this.$route.query.filter,
        filter_comment: filters.length === 0 || filters.indexOf('comment') !== -1,
        filter_post: filters.length === 0 || filters.indexOf('post') !== -1,
        filter_like: filters.length === 0 || filters.indexOf('like') !== -1,
        filter_follow: filters.length === 0 || filters.indexOf('follow') !== -1,
      }
    },
    reset() {
      Object.assign(this.$data, this.initialize());
    },

    fetchData() {
      this.scrollTop()
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}sns/alerts.json`, {
            params: {
              page: this.page,
              filter: this.filter
            },
          })
          .then(response => {
            this.fetch_data = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
            if (location.hash) {
              setTimeout(() => {
                this.scrollTo(location.hash)
              }, 200)
            }
          })
    },

    checkOff() {
      this.filter_comment = this.filter_post = this.filter_like = this.filter_follow = 0
      this.pageChanged(0)
    },

    // ページネーション
    pageChanged(page) {
      const filter = this.$route.query.filter == null ? '' : this.$route.query.filter
      let filters = []
      if (this.filter_comment) filters.push('comment')
      if (this.filter_post) filters.push('post')
      if (this.filter_like) filters.push('like')
      if (this.filter_follow) filters.push('follow')
      const new_filter = filters.join(',')

      if (this.page !== page || new_filter !== filter) {
        let query = {}
        if (new_filter !== '') query.filter = new_filter
        if (page !== 1) query.page = page
        this.$router.push({
          name: 'SnsAlerts',
          params: this.$route.params,
          query: query
        })
      }
    }
  }
}
</script>
